import React, { forwardRef, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import NextPage from '../../components/navigation/next/next-container';
import PreviousPage from '../../components/navigation/previous/previous-container';
import SEO from '../../components/seo/seo';

import useVisibleScrollBar from '../../utils/use-visible-scrollbar';

import './page-16.css';

const PageSixteen = forwardRef((props, ref) => (
  <>
    <SEO title="Page 16" />
    <main className="page page16">
      <div className="page__image-container">
        <StaticImage
          alt="A laundry hamper full of clothing"
          aspectRatio={3500 / 3109}
          className="page__image page__image-border"
          height={1000}
          layout="constrained"
          loading="eager"
          placeholder="tracedSVG"
          src="../../images/page-16.png"
        />
      </div>
      <div
        className="page__text-container"
        ref={ref}
      >
        <div className="page__text">
          <p>
            Then Jimbugs laughed his little squeaky laugh and began to skip off, waving as he went.
          </p>
          <p>
            Amanda waved back and ran towards her house singing
          </p>
          <p>
            <span className="page__text-fancy">
              &ldquo;Fizzle me gumbo, zappy baloo, I want your socks, but just one, not two!&rdquo;
            </span>
          </p>
          <p>
            She was actually looking forward to folding the laundry!
          </p>
        </div>
      </div>
      <PreviousPage to="/page-15" />
      <NextPage to="/end" />
    </main>
  </>
));

const PageSixteenContainer = () => {
  const textRef = useRef();
  useVisibleScrollBar(textRef, 'page__text-container-scroll-indicator');

  return <PageSixteen ref={textRef} />;
};

export default PageSixteenContainer;
